<template>
  <div>
    <van-cell title="票据类型" icon="vip-card-o" is-link :value="selectedName" @click="visible = true" />
    <van-popup round safe-area-inset-bottom position="bottom" title="请选择票据类型" v-model="visible">
      <van-picker
          title="截止时间"
          show-toolbar
          :columns="ticketTypeList"
          @confirm="confirm"
          @cancel="visible = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "TicketTypeCell",
  data() {
    return {
      visible: false,
      selectedName: "全部"
    }
  },
  props: {
    ticketTypeList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    confirm(e) {
      if(e.id === 0) {
        e.id = ''
      }
      this.selectedName = e.text
      this.$emit('input', e.id)
      this.visible = false
    }
  }
}
</script>

<style scoped>
.van-cell__title {
  text-align: start;
}
</style>
