<template>
  <div id="data-view">
    <div class="top-header">
      <dv-decoration-8 class="header-left-decoration" />
      <dv-decoration-8 class="header-right-decoration" :reverse="true" />
      <div class="center-title">{{projectInfo.name || '霜骏网络科技'}}</div>
    </div>
    <div class="main-content" v-if="projectId !== ''">
      <dv-loading v-if="loading"></dv-loading>
      <div v-else >
        <dv-border-box-2 style="height:150px;padding:30px;box-sizing: border-box;">
          <div style="height:100%; display: flex; flex-direction:column; justify-content: space-between;">
            <div style="display: flex; flex-direction: row;">
              <div style="width:100%;height:30px;">已使用</div>
            </div>
            <div style="display: flex; flex-direction: row;">
              <dv-digital-flop :config="countState.out" style="width:100%;height:50px;" />
            </div>
          </div>
        </dv-border-box-2>
        <dv-border-box-11 title="区域总数" style="height:calc(100vh - 145px - 155px);margin-top:20px;box-sizing: border-box;padding:65px 25px;">
          <div class="dv-scroll-ranking-board" style="overflow-y: scroll">
            <div v-for="(item, index) in yuanData" class="row-item" :key="index" style="height: 50px;">
              <div class="ranking-info">
                <div class="rank">No.{{index}}</div>
                <div class="info-name">{{ item.name }}</div>
                <div class="ranking-value">{{ item.value }}</div>
              </div>
              <div class="ranking-column">
                <div class="inside-column">
                  <div class="shine"></div>
                </div>
              </div>
            </div>
          </div>
        </dv-border-box-11>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {Toast} from "vant";
import axios from "axios"

export default {
  name: 'ProjectArea',
  data () {
    return {
      projectId: '',
      projectInfo: {},
      loading: false,
      countState: {},
      yuanData: [],
      minDate: new Date(moment().subtract(1,'month').startOf('month').format('YYYY/MM/DD')),
      maxDate: new Date(moment().format('YYYY/MM/DD')),
      dateValue: '',
      date: '',
      show: false,
    }
  },

  created() {
    this.projectId = this.$route.params.projectId;
    if(this.projectId) {
      this.dateValue = moment().format('MM/DD')
      this.init()
    }
  },

  methods: {
    moment,
    async init() {
      this.loading = true
      await this.getProjectInfo();
      await this.getMockData();
      this.loading = false
    },

    getMockData() {
      const instance = axios.create({
        baseURL: '/'
      });

      instance.get('mock.json?date='+(new Date).getTime())
          .then( res => {
            if(res.data.count.open) {
              let out = res.data.addCount.open ? res.data.count.usedSize + res.data.addCount.usedSize : res.data.count.usedSize
              this.countState = {
                out: {
                  number: [ out ],
                  content: '{nt}人'
                }
              }
            } else {
              this.getStateCountNow(res.data.addCount);
            }

            if(res.data.area.open) {
              this.yuanData = res.data.device.list.map( item => {
                return {
                  name: item.area,
                  value: (item.count || 0) + '人'
                }
              })
            } else {
              this.getAreaSize();
            }
          })
    },

    onConfirm(date) {
      this.show = false;
      this.dateValue = moment(date).format('MM/DD');
      this.init();
    },

    getStateCountNow(addCount) {
      let date = moment().format('YYYY-MM-DD')

      if(this.dateValue !== "") {
        date = moment().format('YYYY') +'-'+ this.dateValue.replace('/','-')
      }

      let params = {projectId: this.projectId, date}

      this.$http.get('/checker/count/countSize', { params })
          .then( res => {
            if(res.data.success){
              let out = addCount.open ? res.data.result.usedSize + addCount.usedSize : res.data.result.usedSize

              this.countState = {
                out: {
                  number: [out],
                  content: '{nt}人'
                }
              }
            } else {
              Toast.fail({
                message: res.data.message,
              });
            }
          })
    },

    getAreaSize() {
      let date = moment().format('YYYY-MM-DD')

      if(this.dateValue !== "") {
        date = moment().format('YYYY') +'-'+ this.dateValue.replace('/','-')
      }

      let params = {projectId: this.projectId, date}

      this.$http.get('/checker/count/countAreaSize', { params })
          .then( res => {
            if(res.data.success){
              let data = res.data.result.map( item => {
                return {
                  name: item.area,
                  value: (item.count || 0) + '人'
                }
              })
              this.yuanData = data;
            } else {
              Toast.fail({
                message: res.data.message,
              });
            }
          })
    },


    getProjectInfo() {
      let params = {
        projectId: this.projectId
      }
      this.$http.get('/checker/count/project', { params })
          .then( res => {
            if(res.data.success){
              this.projectInfo = res.data.result;
            } else {
              Toast.fail({
                message: res.data.message,
              });
            }
          })
    }
  }
}
</script>

<style lang="less" scoped>
#data-view {
  width: 100%;
  min-height: 100vh;
  background-color: #030409;
  background-image: url(~@/assets/bg.png);
  background-size: 100% 100%;
  box-shadow: 0 0 3px blue;

  .main-content {
    color:#fff;
    margin-top: 10px;
  }

  .top-header {
    color: #fff;
    position: relative;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;

    .header-center-decoration {
      width: 40%;
      height: 60px;
      margin-top: 30px;
    }

    .header-left-decoration, .header-right-decoration {
      width: 25%;
      height: 40px;
    }

    .center-title {
      position: absolute;
      font-weight: bold;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
    }

    .date-time {
      position: absolute;
      right: 30px;
      bottom: 0;
    }


  }
  .van-cell {
    color: #fff !important;
    background-color:rgba(0,0,0,0) !important;
    .van-cell__value {
      color: #fff !important;
      text-align: center;
    }
  }
}

.dv-scroll-ranking-board::-webkit-scrollbar {
  display: none;
}

</style>
