<template>
  <div>
    <van-cell title="出入方向" icon="exchange" is-link :value="selectedName" @click="visible = true" />
    <van-popup round safe-area-inset-bottom position="bottom" title="请选择出入方向" v-model="visible">
      <van-picker
          title="截止时间"
          show-toolbar
          :columns="columns"
          @confirm="confirm"
          @cancel="visible = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "DirectionCell",
  data() {
    return {
      visible: false,
      selectedName: "全部",
      columns: [
        {
          direction: 0,
          text: '全部'
        },
        {
          direction: 1,
          text: '进入'
        },
        {
          direction: 2,
          text: '离开'
        }
      ],
    }
  },
  methods: {
    confirm(e) {
      if(e.direction === 0) {
        e.direction = ''
      }
      this.selectedName = e.text
      this.$emit('input', e.direction)
      this.visible = false
    }
  }
}
</script>

<style scoped>

.van-cell__title {
  text-align: start;
}
</style>
