<template>
  <div class="page">
    <div class="header">
      <div class="title">设备设置</div>
      <div class="search-box">
        <van-field
            label="设备码"
            placeholder="请输入设备编码"
            name="deviceCode"
            :value="deviceCode"
            @input="setDeviceCode"
        />
        <van-field
            label="设备名称"
            placeholder="请输入设备名称"
            name="deviceName"
            :value="deviceName"
            @input="setDeviceName"
        />
        <van-button type="primary" block @click="getDeviceInfo">查询</van-button>
      </div>
    </div>
    <div class="body">
      <van-cell
        v-for="(item, index) in deviceList"
        is-link
        :key="index"
        :title="item.name"
        :label="'编号：' + item.androidId"
        :value="item.groupId ? '' : '未分组'"
        @click="showToolPopup(item)"
      />
    </div>
    <van-action-sheet
      title="操作"
      cancel-text="取消"
      :value="showAction"
      :actions="actions"
      @select="onSelect"
      @close="onClose"
      @cancel="onClose"
      @click-overlay="onClose"
    />
    <van-dialog
      use-slot
      title="设置名称"
      :value="showNameModel"
      show-cancel-button
      @confirm="handleSetDeviceName"
      @close="onCloseNameModel"
      @cancel="onCloseNameModel"
    >
      <div style="margin:20px;border-bottom: 0.5px solid #d5d5d5;">
        <van-field
          size="large"
          placeholder="请输入新的设备名称"
          name="deviceCode"
          :value="newDeviceName"
          @input="(e) => newDeviceName = e"
        />
      </div>
    </van-dialog>
    <van-popup
      :close-on-click-overlay="false"
      :value="showGroupModel"
      position="bottom"
      get-container="body"
    >
      <van-picker
        show-toolbar
        value-key="name"
        :columns="groupList"
        @cancel="showGroupModel = false"
        @confirm="handleSetDeviceGroup"
      />
    </van-popup>
    <van-dialog
        use-slot
        :title="groupInfo.name"
        :value="showGroupEditModel"
        confirm-button-text="关闭"
        @confirm="showGroupEditModel = false"
        @close="showGroupEditModel = false"
    >
      <van-cell center title="灯光状态">
        <template v-slot:right-icon>
          <van-switch inactive-color="red" v-model="groupInfo.lightState == 'Y'" size="24" @change="handleSetGroupLight"/>
        </template>
      </van-cell>
      <van-cell center title="人脸对比">
        <template v-slot:right-icon>
          <van-switch inactive-color="red" v-model="groupInfo.compare == 'Y'" size="24"  @change="handleSetGroupCompare"/>
        </template>
      </van-cell>
      <van-cell title="对比精度">
        <template v-slot:right-icon>
          <van-stepper :value="groupInfo.faceScore" :long-press="false" step="0.01" min="0.01" max="1" :decimal-lenght="1" @change="handleSetGroupScore"/>
        </template>
      </van-cell>
    </van-dialog>
  </div>
</template>

<script>

import { Toast } from "vant";

export default {
  name: 'DeviceList',
  data () {
    return {
      showNameModel: false,
      showGroupModel: false,
      showGroupEditModel: false,
      showAction: false,
      actions: [
        {
          name: '设置名称',
          color: 'green'
        },
        {
          name: '设置分组',
          color: 'orange'
        },
        {
          name: '分组操作',
          color: 'red'
        }
      ],
      groupList:[],
      deviceCode: '',
      deviceName: '',
      newDeviceName: '',
      deviceList: [],
      selectedDevice: {},
      groupInfo: {},
      timer: '',
      timer2: ''
    }
  },

  created() {
    this.getGroupList();
  },

  methods: {
    getGroupList() {
      this.$http.get(
          'checker/api/client/deviceGroup/list'
      ).then( res => {
        if( res.data.success ) {
          this.groupList = res.data.result
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    setDeviceCode(e) {
      this.deviceCode = e
      this.onSearch()
    },

    setDeviceName(e) {
      this.deviceName = e
      this.onSearch()
    },

    onSearch() {
      if(this.timer2) {
        clearTimeout(this.timer2)
        this.timer2 = ''
      }

      this.timer2 = setTimeout( () => {
        this.getDeviceInfo()
      }, 1000)
    },

    onClose() {
      this.showAction = false
    },

    onCloseNameModel() {
      this.showNameModel = false
      this.newDeviceName = ''
    },

    onSelect(e) {
      let name = e.name
      this.showAction = false
      switch (name) {
        case '设置名称' :
          this.showNameModel = true
        break;
        case '设置分组' :
          this.showGroupModel = true
          break;
        case '分组操作' :
          if(this.selectedDevice.groupId !== null) {
            this.getGroupInfo();
          } else {
            Toast.fail({
              message: "设备未分组"
            })
          }
        break;
      }
    },

    getDeviceInfo() {
      if(this.deviceCode == "" && this.deviceName == "") {
        Toast.fail({
          message: "请输入设备号或设备名"
        })
        return
      }

      let params = {
        androidId: this.deviceCode,
        name: this.deviceName
      }

      Toast.loading({
        duration: 1,
        message: '加载中...',
        forbidClick: true,
      });

      this.$http.get(
          'checker/api/client/device/search',
          { params }
      ).then( res => {
        if( res.data.success ) {
          this.deviceList = res.data.result
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    getGroupInfo() {
      this.$http.get(
          '/checker/api/client/deviceGroup',
          { params: { groupId: this.selectedDevice.groupId} }
      ).then( res => {
        if( res.data.success ) {
          this.groupInfo = res.data.result
          this.showGroupEditModel = true
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    showToolPopup( record ) {
      this.selectedDevice = record
      this.showAction = true
    },

    handleSetDeviceName() {
      let params = {
        androidId:  this.selectedDevice.androidId,
        name: this.newDeviceName
      }

      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });

      this.$http.post(
        'checker/api/client/device/setName',
         params
      ).then( res => {
        if( res.data.success ) {
          Toast.success({
            message: '操作成功'
          })
          this.onCloseNameModel()
          this.getDeviceInfo()
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    handleSetDeviceGroup(e) {
      let params = {
        androidId:  this.selectedDevice.androidId,
        groupId: e.id
      }

      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });

      this.$http.post(
          'checker/api/client/device/setGroup',
          params
      ).then( res => {
        if( res.data.success ) {
          Toast.success({
            message: '操作成功'
          })
          this.showGroupModel = false
          this.getDeviceInfo()
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    handleSetGroupLight(e) {
      this.$http.post(
          'checker/api/client/deviceGroup/light',
          {
            deviceGroupId:  this.groupInfo.id,
            lightOn: e
          }
      ).then( res => {
        if( res.data.success ) {
          this.groupInfo.lightState = e ? 'Y' : 'N'
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    handleSetGroupCompare(e) {
      this.$http.post(
        'checker/api/client/deviceGroup/compareFace',
        {
          deviceGroupId:  this.groupInfo.id,
          compare: e
        }
      ).then( res => {
        if( res.data.success ) {
          this.groupInfo.compare = e ? 'Y' : 'N'
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    handleSetGroupScore(e) {
      if(e == 0) {
        return
      }

      if(this.timer) {
        clearTimeout(this.timer)
        this.timer = ''
      }

      this.timer = setTimeout( () => {
        this.$http.post(
            'checker/api/client/deviceGroup/faceScore',
            {
              deviceGroupId:  this.groupInfo.id,
              score: e
            }
        ).then( res => {
          if( res.data.success ) {
            Toast.success({
              message: "设置成功"
            })
          } else {
            Toast.fail({
              message: res.data.message,
            });
          }
        })
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  background-image: url(~@/assets/bg.png);
  background-size: 100% 100%;
}

.header {
  height:250px;
  padding:20px 10px;
  box-sizing: border-box;
}

.search-box {
  margin-top:10px;
  border: 1px solid #fff;
  padding:15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
}


.title {
  font-size:20px;
  color:#fff;
}

.body {
  height: calc(100vh - 250px);
  overflow: auto;
}

.body .van-cell {
  color: #fff !important;
  font-size: 16px;
  background: rgba(0,0,0,.1) !important;
}

.van-cell__label {
  font-size: 14px;
  color: #d0cfcf;
}

.van-cell__title {
  text-align: start;
}

.van-cell__right-icon {
  line-height: 46px;
}
</style>
