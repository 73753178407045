<template>
  <div class="page">
    <dv-border-box-11 title="人员列表" style="height:250px;padding:10px 20px;box-sizing: border-box;">
      <div style="margin-top:55px;" class="header">
        <direction-cell v-model="direction"/>
        <van-field left-icon="manager-o" v-model="idNumber" label="证件号" placeholder="请输入证件号" />
        <van-field left-icon="notes-o" v-model="name" label="姓名" placeholder="请输入姓名" />
      </div>
    </dv-border-box-11>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="body">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getOutRankList"
          style="height: calc(100vh - 250px); overflow: auto"
          :offset="100"
      >
        <van-cell
            v-for="(item, index) in list"
            :icon="item.direction == '1' ? require('@/assets/go-home.png') :  require('@/assets/get-out.png')"
            :key="index"
            :title="item.name"
            :value="moment(item.checkTime).format('MM-DD HH:mm')"
            :label="'编号：' + item.idNumber"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>

import { Toast } from "vant";
import moment from "moment";
import DirectionCell from "@/components/DirectionCell";

export default {
  name: 'ProjectPersonList',
  components: {
    DirectionCell
  },
  data () {
    return {
      projectId: '',
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1,
      list: [],
      direction: '',
      idNumber:'',
      name:'',
      timer: ''
    }
  },


  created() {
    this.projectId = this.$route.params.projectId;
    if(this.projectId) {
      this.init()
    }
  },

  methods: {
    moment,
    init() {
      this.getOutRankList();
    },

    onRefresh() {
      if(this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.finished = false;
        this.getOutRankList();
      },1000)
    },

    getOutRankList() {
      if (this.refreshing) {
        this.pageNum = 1
        this.list = [];
        this.refreshing = false;
      }
      let params = {
        projectId: this.projectId,
        direction: this.direction,
        idNumber: this.idNumber,
        name: this.name,
        pageSize: 10,
        pageNum: this.pageNum
      }
      this.loading = true
      this.$http.get(
          '/checker/count/list',
          { params }
      ).then( res => {
        if( res.data.success ) {
          let newList = Object.assign([], this.list)
          this.list = [...newList, ...res.data.result.records]
          if(this.list.length >= res.data.result.total) {
            this.finished = true
          } else {
            this.pageNum += 1;
          }
        } else {
          Toast.fail({
            message: res.data.message,
          });
          this.finished = true
        }
        this.loading = false
      })
    }
  },

  watch: {
    direction() {
      this.refreshing = true;
      this.onRefresh()
    },
    idNumber() {
      this.refreshing = true;
      this.onRefresh()
    },
    name() {
      this.refreshing = true;
      this.onRefresh()
    },
  }
}
</script>

<style lang="less" scoped>
.page {
  background-image: url(~@/assets/bg.png);
  background-size: 100% 100%;
}

.header {
  border-radius: 10px;
  background: #fff;
  padding: 10px;
}

.body .van-cell {
  color: #fff !important;
  background: rgba(0,0,0,.1) !important;
}

.van-cell__title {
  text-align: start;
}

.van-cell__value {
  color: red !important;
}
</style>
