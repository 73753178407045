<template>
  <div>
    <van-cell title="起止日期" icon="clock-o" is-link :value="dateRange" @click="visible = true"/>
    <van-calendar
        allow-same-day
        type="range"
        title="选择起止日期"
        v-model="visible"
        :min-date="minDate"
        :max-date="maxDate"
        :max-range="maxRange"
        @confirm="confirm"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CalendarCell",
  data() {
    return {
      visible: false,
      dateRange: "",
      minDate: new Date(moment().subtract(1,'month').startOf('month').format('YYYY/MM/DD')),
      maxDate: new Date(moment().format('YYYY/MM/DD')),
    }
  },
  props: {
    maxRange: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.dateRange = moment().format('MM/DD') + '-' + moment().format('MM/DD')
  },
  methods: {
    moment,
    confirm( e ) {
      this.dateRange = moment(e[0]).format('MM/DD') + '-' + moment(e[1]).format('MM/DD')
      this.$emit("input", e)
      this.visible = false
    }
  }
}
</script>

<style scoped>
  .van-cell__title {
    text-align: start;
  }
</style>
