<template>
  <div class="page">
    <dv-border-box-11 title="外出时长" style="height:250px;padding:10px 20px;box-sizing: border-box;">
      <div style="margin-top:55px;" class="header">
        <calendar-cell v-model="dateRange"/>
        <time-picker-cell v-model="timeRange"/>
        <ticket-type-cell v-model="ticketType" :ticketTypeList="ticketTypeList"/>
      </div>
    </dv-border-box-11>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="body">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getOutRankList"
          style="height: calc(100vh - 250px); overflow: auto"
          :offset="100"
      >
        <van-cell
          v-for="item in list"
          is-link
          :key="item.id"
          :title="item.name"
          :value="item.minute + '分钟'"
          :label="'编号：' + item.idNumber"
          @click="goToDetail(item)"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>

import { Toast } from "vant";
import moment from "moment";
import CalendarCell from "@/components/CalendarCell";
import TimePickerCell from "@/components/TimePickerCell";
import TicketTypeCell from "@/components/TicketTypeCell";

export default {
  name: 'ProjectList',
  components: {
    CalendarCell,TimePickerCell,TicketTypeCell
  },
  data () {
    return {
      dateRange: [moment().format('YYYY/MM/DD'),moment().format('YYYY/MM/DD')],
      timeRange: ["00:00","23:59"],
      ticketTypeList: [],
      ticketType: '',
      projectId: '',
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1,
      list: [],
    }
  },

  computed: {
    startTime() {
      return moment(this.dateRange[0]).format('YYYY-MM-DD') +  ' ' + this.timeRange[0]+':00'
    },

    endTime() {
      return moment(this.dateRange[1]).format('YYYY-MM-DD') +  ' ' +this.timeRange[1]+':00'
    }
  },

  created() {
    this.projectId = this.$route.params.projectId;
    if(this.projectId) {
      this.init()
    }
  },

  methods: {
    moment,
    init() {
      this.getTicketTypeList();
      this.getOutRankList();
    },

    getTicketTypeList() {
      let params = {
        projectId: this.projectId,
      }
      this.$http.get('/checker/count/ticket/ticketType/list', { params })
      .then( res => {
        if(res.data.success) {
          let list = res.data.result.map( item => {
            return {
              id: item.id,
              text: item.typeName
            }
          })
          this.ticketTypeList = [{id:0, text: '全部'}, ...list]
        } else {
          Toast.fail({
            message: res.data.message,
          });
        }
      })
    },

    onRefresh() {
      this.finished = false;
      this.getOutRankList();
    },

    getOutRankList() {
      if (this.refreshing) {
        this.pageNum = 1
        this.list = [];
        this.refreshing = false;
      }
      let params = {
        projectId: this.projectId,
        startTime: this.startTime,
        endTime: this.endTime,
        ticketTypeId: this.ticketType,
        pageSize: 10,
        pageNum: this.pageNum
      }
      this.loading = true
      this.$http.get(
          '/checker/count/ticket/outRank',
          { params }
      ).then( res => {
        if( res.data.success ) {
          let newList = Object.assign([], this.list)
          this.list = [...newList, ...res.data.result.records]
          if(this.list.length >= res.data.result.total) {
            this.finished = true
          } else {
            this.pageNum += 1;
          }
        } else {
          Toast.fail({
            message: res.data.message,
          });
          this.finished = true
        }
        this.loading = false
      })
    },

    goToDetail( item ) {
      this.$router.push({ name: 'detail', params: { projectId:this.projectId, startTime: this.startTime, endTime: this.endTime, ticketId:item.id, ...item }})
    }
  },

  watch: {
    dateRange() {
      this.refreshing = true;
      this.onRefresh()
    },
    timeRange() {
      this.refreshing = true;
      this.onRefresh()
    },
    ticketType() {
      this.refreshing = true;
      this.onRefresh()
    },
  }
}
</script>

<style lang="less" scoped>
.page {
  background-image: url(~@/assets/bg.png);
  background-size: 100% 100%;
}

.header {
  border-radius: 10px;
  background: #fff;
  padding: 10px;
}

.body .van-cell {
  color: #fff !important;
  background: rgba(0,0,0,.1) !important;
}

.van-cell__title {
  text-align: start;
}

.van-cell__value {
  color: red !important;
}

</style>
