import Vue from "vue";
import VueRouter from 'vue-router'
import ProjectMap from "../views/map/ProjectMap";
import ProjectList from "../views/map/ProjectList";
import ProjectDetail from "../views/map/ProjectDetail";
import ProjectPersonList from "../views/map/ProjectPersonList";
import ProjectArea from "../views/map/ProjectArea";
import DeviceList from "../views/device/DeviceList";
import UpdateJson from "../views/change/UpdateJson";

Vue.use(VueRouter);

const router = new VueRouter({
    mode:'history',
    routes :[
        {
            path: "/device",
            name: 'device',
            component: DeviceList
        },
        {
            path: "/realtime/:projectId",
            name:'realtime',
            component: ProjectMap,
        },
        {
            path: "/area/:projectId",
            name:'area',
            component: ProjectArea,
        },
        {
            path: "/list/:projectId",
            name:'list',
            component: ProjectList,
        },
        {
            path: "/person_list/:projectId",
            name:'person_list',
            component: ProjectPersonList,
        },
        {
            path: "/detail/:projectId",
            name:'detail',
            component: ProjectDetail,
        },
        {
            path: "/change/json",
            name:'update_json',
            component: UpdateJson,
        },
    ]
})

export default router

