<template>
  <div class="page">
    <dv-border-box-11 title="外出详情" style="height:100vh; padding:80px 20px;box-sizing: border-box;">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getCountList"
            style="height: calc(100vh - 100px);overflow: auto"
            :offset="100"
        >
          <van-cell
              v-for="(item, index) in list"
              :icon="item.direction == '1' ? require('@/assets/go-home.png') :  require('@/assets/get-out.png')"
              :key="index"
              :title="item.name"
              :value="moment(item.checkTime).format('MM-DD HH:mm')"
              :label="'编号：' + item.idcard"
          />
        </van-list>
      </van-pull-refresh>
    </dv-border-box-11>

  </div>
</template>

<script>

import { Toast } from "vant";
import moment from "moment";

export default {
  name: 'ProjectDetail',
  data () {
    return {
      searchInfo: {},
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
    }
  },


  created() {
    this.searchInfo = this.$route.params;
    this.init();
  },

  methods: {
    moment,

    init() {
      this.getCountList();
    },

    onRefresh() {
      this.finished = false;
      this.getCountList();
    },

    getCountList() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      let params = {
        ...this.searchInfo
      }
      this.loading = true
      this.$http.get(
          '/checker/count/ticket/checkRecord/list',
          { params }
      ).then( res => {
        if( res.data.success ) {
          this.list = res.data.result
          this.finished = true
        } else {
          Toast.fail({
            message: res.data.message,
          });
          this.finished = true
        }
        this.loading = false
      })
    },
  }
}
</script>

<style lang="less" scoped>
.page {
  background-image: url(~@/assets/bg.png);
  background-size: 100% 100%;
}

.van-cell {
  color: #fff !important;
  background: rgba(0,0,0,.1) !important;
}

.van-cell__title {
  text-align: start;
}

.van-cell__value {
  color: red !important;
}

.van-cell__left-icon {
  font-size: 40px !important;
}
</style>
