<template>
  <div>
    <van-cell title="起止时间" icon="clock-o" is-link :value="timeRange" @click="visible = true" />
    <van-popup round closeable safe-area-inset-bottom position="bottom" title="请选择起止时间" v-model="visible">
      <div style="display: flex;flex-direction: row">
        <div style="width: 50%">
          <van-datetime-picker
              v-model="startTime"
              type="time"
              :formatter="formatter"
          >
            <div style="width:100%; text-align: center">起始时间 </div>
          </van-datetime-picker>
        </div>
        <div style="width: 50%">
          <van-datetime-picker
              title="截止时间"
              v-model="endTime"
              type="time"
              :formatter="formatter"
          >
            <div style="width:100%; text-align: center">终止时间 </div>
          </van-datetime-picker>
        </div>
      </div>
      <div style="padding: 20px;">
        <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="confirm">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TimePickerCell",
  data() {
    return {
      visible: false,
      startTime: "00:00",
      endTime: "23:59",
    }
  },
  computed: {
    timeRange() {
      return this.startTime + '-' + this.endTime
    }
  },

  methods: {
    moment,

    formatter(type, val) {
      if (type === 'hour') {
        return `${val}时`;
      } else if (type === 'minute') {
        return `${val}分`;
      }
      return val;
    },

    confirm(  ) {
      let timeRange = [this.startTime, this.endTime]
      this.$emit("input", timeRange)
      this.visible = false
    }
  }
}
</script>

<style scoped>
.van-cell__title {
  text-align: start;
}
</style>
