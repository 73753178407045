<template>
  <div class="page">
    <form>
      <van-cell-group title="原始数据" inset>
        <van-cell title="开关">
          <template v-slot:right-icon>
            <van-switch v-model="checked1" @change="onChange1" />
          </template>
        </van-cell>
        <van-field
            name=""
            label="已使用人数"
            type="number"
            :value="used1"
            placeholder="请输入已使用人数"
            :border="false"
        />
      </van-cell-group>
      <div style="padding: 15px;">
        <van-button type="primary" block @click="saveCount">保存</van-button>
      </div>

      <van-cell-group title="增加数据" inset>
        <van-cell title="开关">
          <template v-slot:right-icon>
            <van-switch v-model="checked2" @change="onChange2" />
          </template>
        </van-cell>
        <van-field
            name=""
            label="已使用人数"
            type="number"
            :value="used2"
            placeholder="请输入已使用人数"
            :border="false"
        />
      </van-cell-group>
      <div style="padding: 15px;">
        <van-button type="primary" block @click="saveAddCount">保存</van-button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios"

const instance = axios.create({
  baseURL: '/'
});

export default {
  name: "UpdateJson",
  data() {
    return {
      jsonData: {},
      change: '',
      checked1: false,
      checked2: false,
      used1: 0,
      used2: 0
    }
  },

  mounted() {
    this.getJson()
  },

  methods: {
    getJson() {
      instance.get('mock.json?date='+(new Date).getTime())
      .then( res => {
        this.jsonData = res.data
        this.checked1 = res.data.count.open
        this.checked2 = res.data.addCount.open
        this.used1 = res.data.count.usedSize
        this.used2 = res.data.addCount.usedSize
      })
    },

    onChange1(e) {
      this.checked1 = e
    },

    onChange2(e) {
      this.checked2 = e
    },

    saveCount() {
      let json = Object.assign(this.jsonData)
      json.count = {
        open: this.checked1,
        usedSize: this.used1
      }
      this.setJson(json)
    },

    saveAddCount() {
      let json = Object.assign(this.jsonData)
      json.addCount = {
        open: this.checked2,
        usedSize: this.used2
      }
      this.setJson(json)
    },

    setJson(json) {

    }
  }
}
</script>

<style scoped>
  .page {
    height: 100vh;
    text-align: start;
    background-color: #e6e6e6;
  }

</style>
<!--
{
  "count": {
    "open": false,
    "usedSize": 0
  },
  "addCount": {
    "open": false,
    "usedSize": 50
  },
  "device": {
    "open": false,
    "list": [
      {
        "area": "A区",
        "count": 0
      }
    ]
  },
  "area": {
    "open": false,
    "list": [
      {
        "area": "南门",
        "count": 4813
      }
    ]
  }
}
-->
