import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import axios from "axios";
import router from './router/index'
import Vant from 'vant';
import 'vant/lib/index.css';

axios.defaults.baseURL = "https://api.cdsjkj.tech"

Vue.use(dataV)
Vue.use(Vant);

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
